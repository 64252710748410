<ion-card>
  <ion-card-content class="ion-text-center">
    <div style="margin-left: auto; margin-right: auto">
      <ion-grid class="flusso-card-location">
        <ion-row color="primary">
          <ion-col size="auto">
            <ion-icon name="location" color="secondary" class="flusso-card-icon"></ion-icon>
          </ion-col>
          <ion-col size="auto" class="ion-text-start">
            Hamersveldseweg 126
            <br/>
            3833 GV Leusden
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="auto">
            <ion-icon name="location" color="secondary" class="flusso-card-icon"></ion-icon>
          </ion-col>
          <ion-col size="auto" class="ion-text-start">
            Rijksstraatweg 79
            <br/>
            9756 AD Glimmen
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <br/>
    <div>
      <div class="flusso-card-info-line" (click)="gotoFlusso()">
        <ion-icon name="globe" color="secondary" class="flusso-card-icon"></ion-icon>
        &nbsp;&nbsp;
        <a>www.flusso.nl</a>
      </div>
    </div>
    <div>
      <div class="flusso-card-info-line">
        <ion-icon name="mail" color="secondary" class="flusso-card-icon"></ion-icon>
        &nbsp;&nbsp;
        <a href="mailto:info@flusso.nl">info&#64;flusso.nl</a>
      </div>
    </div>
    <div>
      <div class="flusso-card-info-line">
        <ion-icon name="call" color="secondary" class="flusso-card-icon"></ion-icon>
        &nbsp;&nbsp;
        <a href="tel:+31 33 4347680">033 - 4347680</a>
      </div>
    </div>
  </ion-card-content>
</ion-card>
