import { Component, OnInit, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ad6-about-segment',
  templateUrl: './ad6-about-segment.component.html',
  styleUrls: ['./ad6-about-segment.component.scss'],
  standalone: true,
})
export class Ad6AboutSegmentComponent implements OnInit {
  @Input() image: string;
  @Input() link: string;
  @Input() segment: string;

  private translateService = inject(TranslateService);
  firstContent: string;
  secondContent: string;
  src: string;

  title: string;

  ngOnInit(): void {
    this.src = `assets/images/${this.image}`;
    const titleTranslationKey = `about.title.${this.segment}`;
    const firstContentTranslationKey = `about.content.${this.segment}.first`;
    const secondContentTranslationKey = `about.content.${this.segment}.second`;

    this.translateService.get([
      titleTranslationKey,
      firstContentTranslationKey,
      secondContentTranslationKey,
    ]).subscribe(translations => {
      this.title = translations[titleTranslationKey];
      this.firstContent = translations[firstContentTranslationKey];
      if (translations[secondContentTranslationKey] !== secondContentTranslationKey) {
        this.secondContent = translations[secondContentTranslationKey];
      }
    });
  }
}
