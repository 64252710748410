import { Component } from '@angular/core';
import { Constants } from '@app/constants';
import { Browser } from '@capacitor/browser';
import {
  IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,
} from '@ionic/angular/standalone';

@Component({
  selector: 'ad6-card-flusso',
  templateUrl: './ad6-card-flusso.component.html',
  styleUrls: ['./ad6-card-flusso.component.scss'],
  standalone: true,
  imports: [
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
  ],
})
export class Ad6CardFlussoComponent {

  gotoFlusso(): void {
    Browser.open({ url: Constants.flussoWebsite });
  }
}
